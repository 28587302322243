import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	class?: string;
	variant?: 'default' | 'prominent' | 'subtle';
}>;

export function MessageBanner(props: Props) {
	return (
		<div
			class={twMerge(
				'-mb-6 flex w-screen flex-row items-center justify-center gap-x-2 self-end bg-brand-100 px-8 pb-10 pt-6 text-center text-brand md:pb-8',
				props.variant === 'prominent' && 'bg-brand text-white',
				props.variant === 'subtle' && 'bg-neutral-300 text-neutral-900',
				props.class,
			)}
		>
			{props.children}
		</div>
	);
}
